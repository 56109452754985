.Topbar {
    min-width: 100%;
    width: fit-content;
    background-color: rgba(29, 29, 29, 0);
    border-bottom: 3px solid #ffffff42;
    transition: background-color 0.4s, border-bottom 0.4s;
    padding-left: 4vmin;
    padding-right: 8vmin;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    overflow: hidden;
}

.Topbar:hover{
    background-color: rgba(51, 51, 51, 0.144);
    border-bottom: 3px solid #ffffff;
}

.topbar-logo {
    width: 5rem;
    height: 5rem;
    background-image: url('../../../assets/images/logo.png');
    background-size: cover;
    background-position: center;
}

.topbar-organization {
    display: flex;
    cursor: pointer;
    
}

.topbar-organization h1{
    color: #ffffff;
    font-size: 1.5rem;
    margin: auto;
}

.topbar-menu {
    align-items: center;
    text-align: center;
    display: flex;
    color: #ffffff !important;
}

.topbar-menu nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 2rem;
}

.topbar-menu a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.topbar-sidebar {
    display: none;
}

.topbar-dropdown {
    color: #ffffff;
    cursor: pointer;
    font-size: 3rem;
}

@media (max-width: 480px) {
    .topbar-organization h1{
        display: none;
    }

    .topbar-menu {
        display: none;
    }
}

@media (max-width: 1000px) {
    .topbar-menu {
        display: none;
    }

    .topbar-sidebar {
        display: block;
    }
    
}