.Footbar {
    background-color: rgb(37, 37, 37);
    min-height: 10rem;
    height: fit-content;
    min-width: 100%;
    width: fit-content;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    text-align: center;
    position: relative;
    border-top: 1px solid rgb(255, 255, 255);
}

.Footbar-menu nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5vw;
}

.Footbar-menu a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
}

.Footbar-organization {
    color: white;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
}

.Footbar-organization h1{
    font-size: 1rem;
    padding-top: 0.5rem;
}


.Footbar-logo {
    width: 4rem;
    height: 4rem;
    background-image: url('../../../assets/images/logo.png');
    background-size: cover;
    background-position: center;
}

@media (max-width: 600px) {
    .Footbar {
        min-height: 50vh;
    }

    .Footbar-menu nav ul {
        flex-direction: column;
    }
}