  
  .marquee-scrollable {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
  }
  
  .marquee-scrollable .scroller {
    font-family: "Tomorrow", sans-serif;
    font-weight: 500;
    font-style: italic;
    text-transform: uppercase;
    font-size: 4rem;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    word-spacing: 3rem;
  }
  
  .marquee-scrollable span {
    display: block;
    margin-right: 3rem;
  }
  
  @media (max-width: 500px) {
    .marquee-scrollable .scroller {
      font-size: 8vmin;
    }
}