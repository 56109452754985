.generic-container {
    margin-top: 5rem;
    min-height: 100vh;
    position: relative;
}

.generic-container .info {
    margin-left: 10vw;
    margin-right: 10vw;
}

.generic-container h1,
.generic-container h3,
.generic-container h4 {
    color: white;
}

.generic-container h1 {
    font-size: 6rem;
}

.generic-container h3 {
    margin-top: 2rem;
    font-size: 2.5rem;
}

.generic-container h4 {
    margin-top: 2rem;
    font-size: 1.5rem;
}

.generic-container button {
    margin-top: 3rem;
    background-color: #07172997; /* Complementary color to the background */
    color: #ffffff; /* White text for better contrast */
    padding: 1rem 2rem; /* Increased padding for a better look */
    font-size: 1rem; /* Slightly larger font size */
    font-weight: bold; /* Bold text */
    border: none; /* Remove border */
    border-radius: 12px; /* Increased border-radius for a modern look */
    box-shadow: 0 4px 6px #071729; /* Subtle shadow for better visual effect */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.generic-container button:hover {
    background-color: #0c2540; /* Darker shade on hover */
}

.white-link {
    color: white;
  }
  
.white-link:hover {
    text-decoration: none; /* Optional: adds underline on hover */
  }

@media (max-width: 500px) {
    .generic-container h1 {
        font-size: 13vw; 
    }

    .generic-container h3 {
        font-size: 6vw; 
    }

    .generic-container h4 {
        font-size: 3vw; 
    }

    .generic-container button {
        font-size: 5vw;
        width: fit-content;
    }
}