.tokens-and-exchanges-marquee{
    background-color: rgba(38, 53, 56, 0.773);
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 12vmin;
}

.tokens-and-exchanges-marquee h3{
    font-family: "Tomorrow", sans-serif;
    margin-top: 0rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

@media (max-width: 500px) {
    .tokens-and-exchanges-marquee h3 {
        font-size: 5vw;
    }
}