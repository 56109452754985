.not-found-container {
    padding-top: 5rem;
    padding-left: 5rem;
    height: 100vh;
}

.not-found-container h1,
.not-found-container h3 {
    color: white;
}

.not-found-container span {
    display: flex; 
    font-size: 3rem;
}

.not-found-container h3 a {
    color: #ffa480; 
    text-decoration: none;
}

.not-found-container h3 a:hover {
    text-decoration: underline; 
}