.home-container-title {
    margin-top: 5rem;
    margin-left: 10vw;
    margin-right: 10vw;
}

.home-container-title h1 {
    font-size: 5rem; 
    color: white !important;

}

.home-container-title h3 {
    margin-top: 5rem;
    font-size: 2rem; 
    color: white !important;
}

.home-container {
    width: 100%;
    scroll-snap-align: start;
    height: 100vh;
}

.typewriter-underline {
    text-decoration: underline; /* Underline the text */
    text-decoration-color: #ffffff; /* Optional: Change the color of the underline */
    text-decoration-thickness: 0.2rem; /* Optional: Adjust the thickness of the underline */
}

@media (max-width: 500px) {
    .home-container-title {
        margin-top: 30vw;
    }

    .home-container-title h1 {
        font-size: 13vmin; 
    }

    .home-container-title h3 {
        font-size: 6vmin; 
    }
}