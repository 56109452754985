.common-information-container {
    min-height: 45rem;
    width: 100%;
    background-color: rgb(1, 42, 33);
    border-top: 1px solid rgb(255, 255, 255);
    border-bottom: 1px solid black;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 15vw;
    padding-right: 15vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.common-information-container h1, span {
    color: white;
}

.common-information-container h1 {
    font-size: 4rem;
}

.common-information-container span {
    font-size: 1.5rem;
}

@media (max-width: 500px) {
    .common-information-container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .common-information-container h1 {
        font-size: 11vw;
    }
    
    .common-information-container span {
        font-size: 5vw;
    }
}