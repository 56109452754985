.slide-sidebar {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.945); /* Semi-transparent background */
    z-index: 1000; 
    overflow: scroll;
}

.close-icon-frame {
    width: 100%;
    font-size: 3rem;
    margin-left: 2rem;
}

.close-icon {
    width: fit-content;
    cursor: pointer;
}

.sidebar-menu {
    align-items: center;
    text-align: center;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sidebar-menu nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.sidebar-menu a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 2.5rem;
}

.sidebar-menu a:hover {
    color: #1e9a9f;
}
